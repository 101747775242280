import Button from '@swe/shared/ui-kit/components/button';
import { isSSR } from '@swe/shared/utils/environment';

import { ApplicationPage } from '@swe/shop-ui/app/types';

const DebugClientRenderError: ApplicationPage = () => {
  if (!isSSR) {
    throw new Error('My tiny only Client render error');
  }

  return (
    <div>
      <Button
        ariaLabel="click to error"
        onClick={() => {
          throw new Error('My tiny click handler error');
        }}
      >
        Click me to test cb error
      </Button>
    </div>
  );
};

export default DebugClientRenderError;
